<template>
    <div class="action-confirmed-profile">
        <template>
            <div class="message">
                <div class="message-top">
                    <img class="m-r-8" :src="require('@/assets/images/chat/profile-fin.png')" alt="" />
                    <span class="f-16 spoqa-f-bold">프로필 확인이 완료되었어요!</span>
                </div>
                <div class="message-content">
                    <p>이후 내용을 수정하시면 매니저 확인 후 프로필에 반영됩니다.</p>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
export default {
    name: 'ActionConfirmedProfile',
    props: ['message'],
}
</script>

<style scoped lang="scss">
.action-confirmed-profile {
    padding: 0 16px;
}
.message-top {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
}
.message-content {
    line-height: 26px;
    color: black;

    p {
        margin-bottom: 16px;
    }
}
</style>
